import * as React from "react";

import Layout from "../components/layout";

const IndexPage = ({ data }) => {
  console.log(data)
  return (
    <Layout></Layout>
  );
}

export default IndexPage;
